@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,300,600,700&display=swap");
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

:root {
  --primary: #2AD0B6;
  --accent: #146464;
  --bgc-theme-toolbar: var(--accent);
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.bg-accent {
  background-color: var(--accent) !important;
}

.text-accent {
  color: var(--accent) !important;
}

.bg-success {
  background-color: #2AD0B6 !important;
}

.text-success {
  color: #2AD0B6 !important;
}

.bg-warning {
  background-color: #ffb22b !important;
}

.text-warning {
  color: #ffb22b !important;
}

.bg-purple {
  background-color: #7460ee !important;
}

.text-purple {
  color: #7460ee !important;
}

.bg-error {
  background-color: #fc4b6c !important;
}

.text-error {
  color: #fc4b6c !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.text-white {
  color: #ffffff !important;
}

.bg-light {
  background-color: #f2f6fa !important;
}

.text-light {
  color: #f2f6fa !important;
}

.bg-gray-100 {
  background-color: #7c8fac !important;
}

.text-gray-100 {
  color: #7c8fac !important;
}

.bg-light-error {
  background-color: #f9e7eb !important;
}

.text-light-error {
  color: #f9e7eb !important;
}

.bg-light-accent {
  background-color: #e8f7ff !important;
}

.text-light-accent {
  color: #e8f7ff !important;
}

.bg-light-success {
  background-color: #e8fdf8 !important;
}

.text-light-success {
  color: #e8fdf8 !important;
}

.bg-light-warning {
  background-color: #fff8ec !important;
}

.text-light-warning {
  color: #fff8ec !important;
}

.bg-light-primary {
  background-color: #ecf6ff !important;
}

.text-light-primary {
  color: #ecf6ff !important;
}

.fill-warning svg {
  fill: #ffb22b;
  color: #ffb22b;
}

.fill-light svg {
  fill: #f2f6fa;
  color: #f2f6fa;
}

.icon-1 {
  height: 1px !important;
  width: 1px !important;
}

.icon-2 {
  height: 2px !important;
  width: 2px !important;
}

.icon-3 {
  height: 3px !important;
  width: 3px !important;
}

.icon-4 {
  height: 4px !important;
  width: 4px !important;
}

.icon-5 {
  height: 5px !important;
  width: 5px !important;
}

.icon-6 {
  height: 6px !important;
  width: 6px !important;
}

.icon-7 {
  height: 7px !important;
  width: 7px !important;
}

.icon-8 {
  height: 8px !important;
  width: 8px !important;
}

.icon-9 {
  height: 9px !important;
  width: 9px !important;
}

.icon-10 {
  height: 10px !important;
  width: 10px !important;
}

.icon-11 {
  height: 11px !important;
  width: 11px !important;
}

.icon-12 {
  height: 12px !important;
  width: 12px !important;
}

.icon-13 {
  height: 13px !important;
  width: 13px !important;
}

.icon-14 {
  height: 14px !important;
  width: 14px !important;
}

.icon-15 {
  height: 15px !important;
  width: 15px !important;
}

.icon-16 {
  height: 16px !important;
  width: 16px !important;
}

.icon-17 {
  height: 17px !important;
  width: 17px !important;
}

.icon-18 {
  height: 18px !important;
  width: 18px !important;
}

.icon-19 {
  height: 19px !important;
  width: 19px !important;
}

.icon-20 {
  height: 20px !important;
  width: 20px !important;
}

.icon-21 {
  height: 21px !important;
  width: 21px !important;
}

.icon-22 {
  height: 22px !important;
  width: 22px !important;
}

.icon-23 {
  height: 23px !important;
  width: 23px !important;
}

.icon-24 {
  height: 24px !important;
  width: 24px !important;
}

.icon-25 {
  height: 25px !important;
  width: 25px !important;
}

.icon-26 {
  height: 26px !important;
  width: 26px !important;
}

.icon-27 {
  height: 27px !important;
  width: 27px !important;
}

.icon-28 {
  height: 28px !important;
  width: 28px !important;
}

.icon-29 {
  height: 29px !important;
  width: 29px !important;
}

.icon-30 {
  height: 30px !important;
  width: 30px !important;
}

.icon-31 {
  height: 31px !important;
  width: 31px !important;
}

.icon-32 {
  height: 32px !important;
  width: 32px !important;
}

.icon-33 {
  height: 33px !important;
  width: 33px !important;
}

.icon-34 {
  height: 34px !important;
  width: 34px !important;
}

.icon-35 {
  height: 35px !important;
  width: 35px !important;
}

.icon-36 {
  height: 36px !important;
  width: 36px !important;
}

.icon-37 {
  height: 37px !important;
  width: 37px !important;
}

.icon-38 {
  height: 38px !important;
  width: 38px !important;
}

.icon-39 {
  height: 39px !important;
  width: 39px !important;
}

.icon-40 {
  height: 40px !important;
  width: 40px !important;
}

.icon-41 {
  height: 41px !important;
  width: 41px !important;
}

.icon-42 {
  height: 42px !important;
  width: 42px !important;
}

.icon-43 {
  height: 43px !important;
  width: 43px !important;
}

.icon-44 {
  height: 44px !important;
  width: 44px !important;
}

.icon-45 {
  height: 45px !important;
  width: 45px !important;
}

.icon-46 {
  height: 46px !important;
  width: 46px !important;
}

.icon-47 {
  height: 47px !important;
  width: 47px !important;
}

.icon-48 {
  height: 48px !important;
  width: 48px !important;
}

.icon-49 {
  height: 49px !important;
  width: 49px !important;
}

.icon-50 {
  height: 50px !important;
  width: 50px !important;
}

.icon-51 {
  height: 51px !important;
  width: 51px !important;
}

.icon-52 {
  height: 52px !important;
  width: 52px !important;
}

.icon-53 {
  height: 53px !important;
  width: 53px !important;
}

.icon-54 {
  height: 54px !important;
  width: 54px !important;
}

.icon-55 {
  height: 55px !important;
  width: 55px !important;
}

.icon-56 {
  height: 56px !important;
  width: 56px !important;
}

.bg-primary,
.topbar {
  background-color: var(--bgc-theme-toolbar) !important;
  background-image: var(--bgi-theme-toolbar) !important;
  background-repeat: repeat-x !important;
  background-size: 45px !important;
}

.topbar .mat-mdc-icon-button,
.topbar .mat-mdc-button {
  color: #ffffff !important;
}

.horizontal-navbar .parentBox.pactive > a,
.horizontal-navbar .parentBox.pactive > a:hover,
.horizontal-navbar .parentBox.pactive:hover > a {
  background-color: var(--primary) !important;
}

.activeMenu {
  background-color: var(--primary) !important;
}

.mat-mdc-outlined-button.mat-primary {
  border-color: var(--primary) !important;
}
.mat-mdc-outlined-button.mat-accent {
  border-color: var(--primary) !important;
}
.mat-mdc-outlined-button.mat-warn {
  border-color: #e91e63 !important;
}

main {
  background-color: var(--bgc-theme);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: var(--primary);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: var(--primary);
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #e91e63;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #e91e63;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #e91e63;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Poppins;
  --mat-option-label-text-line-height: 20px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Poppins;
  --mat-optgroup-label-text-line-height: 20px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Poppins;
  --mat-card-title-text-line-height: 26px;
  --mat-card-title-text-size: 18px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Poppins;
  --mat-card-subtitle-text-line-height: 14px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 400;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--primary);
  --mdc-linear-progress-track-color: var(--primary);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(--primary);
  --mdc-linear-progress-track-color: var(--primary);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #e91e63;
  --mdc-linear-progress-track-color: rgba(233, 30, 99, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Poppins;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: var(--primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary);
  --mdc-filled-text-field-focus-label-text-color: var(--primary);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #e91e63;
  --mdc-filled-text-field-error-focus-label-text-color: #e91e63;
  --mdc-filled-text-field-error-label-text-color: #e91e63;
  --mdc-filled-text-field-error-caret-color: #e91e63;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #e91e63;
  --mdc-filled-text-field-error-focus-active-indicator-color: #e91e63;
  --mdc-filled-text-field-error-hover-active-indicator-color: #e91e63;
  --mdc-outlined-text-field-caret-color: var(--primary);
  --mdc-outlined-text-field-focus-outline-color: var(--primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #e91e63;
  --mdc-outlined-text-field-error-focus-label-text-color: #e91e63;
  --mdc-outlined-text-field-error-label-text-color: #e91e63;
  --mdc-outlined-text-field-error-hover-label-text-color: #e91e63;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #e91e63;
  --mdc-outlined-text-field-error-hover-outline-color: #e91e63;
  --mdc-outlined-text-field-error-outline-color: #e91e63;
  --mat-form-field-focus-select-arrow-color: var(--primary);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #e91e63;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: var(--primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary);
  --mdc-filled-text-field-focus-label-text-color: var(--primary);
  --mdc-outlined-text-field-caret-color: var(--primary);
  --mdc-outlined-text-field-focus-outline-color: var(--primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary);
  --mat-form-field-focus-select-arrow-color: var(--primary);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #e91e63;
  --mdc-filled-text-field-focus-active-indicator-color: #e91e63;
  --mdc-filled-text-field-focus-label-text-color: rgba(233, 30, 99, 0.87);
  --mdc-outlined-text-field-caret-color: #e91e63;
  --mdc-outlined-text-field-focus-outline-color: #e91e63;
  --mdc-outlined-text-field-focus-label-text-color: rgba(233, 30, 99, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(233, 30, 99, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Poppins;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Poppins;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Poppins;
  --mat-form-field-container-text-line-height: 20px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Poppins;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--primary);
  --mat-select-invalid-arrow-color: rgba(233, 30, 99, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: var(--primary);
  --mat-select-invalid-arrow-color: rgba(233, 30, 99, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(233, 30, 99, 0.87);
  --mat-select-invalid-arrow-color: rgba(233, 30, 99, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Poppins;
  --mat-select-trigger-text-line-height: 20px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Poppins;
  --mdc-dialog-subhead-line-height: 26px;
  --mdc-dialog-subhead-size: 18px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Poppins;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: var(--primary);
  --mdc-chip-elevated-selected-container-color: var(--primary);
  --mdc-chip-elevated-disabled-container-color: var(--primary);
  --mdc-chip-flat-disabled-selected-container-color: var(--primary);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: var(--primary);
  --mdc-chip-elevated-selected-container-color: var(--primary);
  --mdc-chip-elevated-disabled-container-color: var(--primary);
  --mdc-chip-flat-disabled-selected-container-color: var(--primary);
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #e91e63;
  --mdc-chip-elevated-selected-container-color: #e91e63;
  --mdc-chip-elevated-disabled-container-color: #e91e63;
  --mdc-chip-flat-disabled-selected-container-color: #e91e63;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Poppins;
  --mdc-chip-label-text-line-height: 22px;
  --mdc-chip-label-text-size: 16px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Poppins;
  --mdc-form-field-label-text-line-height: 22px;
  --mdc-form-field-label-text-size: 16px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: var(--primary);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: var(--primary);
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e91e63;
  --mdc-radio-selected-hover-icon-color: #e91e63;
  --mdc-radio-selected-icon-color: #e91e63;
  --mdc-radio-selected-pressed-icon-color: #e91e63;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #e91e63;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Poppins;
  --mdc-form-field-label-text-line-height: 22px;
  --mdc-form-field-label-text-size: 16px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: var(--primary);
  --mdc-slider-focus-handle-color: var(--primary);
  --mdc-slider-hover-handle-color: var(--primary);
  --mdc-slider-active-track-color: var(--primary);
  --mdc-slider-inactive-track-color: var(--primary);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary);
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: var(--primary);
  --mat-slider-hover-state-layer-color: rgba(var(--primary), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--primary), 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: var(--primary);
  --mat-slider-hover-state-layer-color: rgba(var(--primary), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--primary), 0.2);
  --mdc-slider-handle-color: var(--primary);
  --mdc-slider-focus-handle-color: var(--primary);
  --mdc-slider-hover-handle-color: var(--primary);
  --mdc-slider-active-track-color: var(--primary);
  --mdc-slider-inactive-track-color: var(--primary);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary);
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #e91e63;
  --mat-slider-hover-state-layer-color: rgba(233, 30, 99, 0.05);
  --mat-slider-focus-state-layer-color: rgba(233, 30, 99, 0.2);
  --mdc-slider-handle-color: #e91e63;
  --mdc-slider-focus-handle-color: #e91e63;
  --mdc-slider-hover-handle-color: #e91e63;
  --mdc-slider-active-track-color: #e91e63;
  --mdc-slider-inactive-track-color: #e91e63;
  --mdc-slider-with-tick-marks-inactive-container-color: #e91e63;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mdc-slider-label-label-text-font: Poppins;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 14px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 400;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Poppins;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 20px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: var(--primary);
  --mdc-radio-selected-hover-icon-color: var(--primary);
  --mdc-radio-selected-icon-color: var(--primary);
  --mdc-radio-selected-pressed-icon-color: var(--primary);
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e91e63;
  --mdc-radio-selected-hover-icon-color: #e91e63;
  --mdc-radio-selected-icon-color: #e91e63;
  --mdc-radio-selected-pressed-icon-color: #e91e63;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e91e63;
  --mdc-checkbox-selected-hover-state-layer-color: #e91e63;
  --mdc-checkbox-selected-pressed-state-layer-color: #e91e63;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--primary);
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--primary);
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Poppins;
  --mdc-list-list-item-label-text-line-height: 20px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Poppins;
  --mdc-list-list-item-supporting-text-line-height: 22px;
  --mdc-list-list-item-supporting-text-size: 16px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Poppins;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 500 21px / 26px Poppins;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: Poppins;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--primary);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--primary);
  --mat-tab-header-active-ripple-color: var(--primary);
  --mat-tab-header-inactive-ripple-color: var(--primary);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--primary);
  --mat-tab-header-active-hover-label-text-color: var(--primary);
  --mat-tab-header-active-focus-indicator-color: var(--primary);
  --mat-tab-header-active-hover-indicator-color: var(--primary);
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: var(--primary);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--primary);
  --mat-tab-header-active-ripple-color: var(--primary);
  --mat-tab-header-inactive-ripple-color: var(--primary);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--primary);
  --mat-tab-header-active-hover-label-text-color: var(--primary);
  --mat-tab-header-active-focus-indicator-color: var(--primary);
  --mat-tab-header-active-hover-indicator-color: var(--primary);
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #e91e63;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #e91e63;
  --mat-tab-header-active-ripple-color: #e91e63;
  --mat-tab-header-inactive-ripple-color: #e91e63;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e91e63;
  --mat-tab-header-active-hover-label-text-color: #e91e63;
  --mat-tab-header-active-focus-indicator-color: #e91e63;
  --mat-tab-header-active-hover-indicator-color: #e91e63;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: var(--primary);
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: var(--primary);
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #e91e63;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Poppins;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 400;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary);
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #e91e63;
  --mdc-checkbox-selected-hover-icon-color: #e91e63;
  --mdc-checkbox-selected-icon-color: #e91e63;
  --mdc-checkbox-selected-pressed-icon-color: #e91e63;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e91e63;
  --mdc-checkbox-selected-hover-state-layer-color: #e91e63;
  --mdc-checkbox-selected-pressed-state-layer-color: #e91e63;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Poppins;
  --mdc-form-field-label-text-line-height: 22px;
  --mdc-form-field-label-text-size: 16px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: var(--primary);
  --mat-text-button-state-layer-color: var(--primary);
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: var(--primary);
  --mat-text-button-state-layer-color: var(--primary);
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #e91e63;
  --mat-text-button-state-layer-color: #e91e63;
  --mat-text-button-ripple-color: rgba(233, 30, 99, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: var(--primary);
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: var(--primary);
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #e91e63;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--primary);
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--primary);
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #e91e63;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: var(--primary);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: var(--primary);
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: var(--primary);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: var(--primary);
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #e91e63;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #e91e63;
  --mat-outlined-button-ripple-color: rgba(233, 30, 99, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Poppins;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 400;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Poppins;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 400;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Poppins;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 400;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Poppins;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 400;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: var(--primary);
  --mat-icon-button-state-layer-color: var(--primary);
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: var(--primary);
  --mat-icon-button-state-layer-color: var(--primary);
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #e91e63;
  --mat-icon-button-state-layer-color: #e91e63;
  --mat-icon-button-ripple-color: rgba(233, 30, 99, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: var(--primary);
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: var(--primary);
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #e91e63;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--primary);
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: var(--primary);
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #e91e63;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Poppins;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 400;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: var(--primary);
}

html {
  --mdc-snackbar-supporting-text-font: Poppins;
  --mdc-snackbar-supporting-text-line-height: 22px;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Poppins;
  --mat-table-header-headline-line-height: 14px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 400;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Poppins;
  --mat-table-row-item-label-text-line-height: 22px;
  --mat-table-row-item-label-text-size: 16px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Poppins;
  --mat-table-footer-supporting-text-line-height: 22px;
  --mat-table-footer-supporting-text-size: 16px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: var(--primary);
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--primary);
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #e91e63;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: var(--primary);
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: var(--primary);
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #e91e63;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Poppins;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Poppins;
  --mat-bottom-sheet-container-text-line-height: 22px;
  --mat-bottom-sheet-container-text-size: 16px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Poppins;
  --mat-legacy-button-toggle-label-text-line-height: 20px;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Poppins;
  --mat-standard-button-toggle-label-text-line-height: 20px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary);
  --mat-datepicker-toggle-active-state-icon-color: var(--primary);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--primary), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 0, 0, 0.26);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--primary), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #e91e63;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(233, 30, 99, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(233, 30, 99, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(233, 30, 99, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(233, 30, 99, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: var(--primary);
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #e91e63;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Poppins;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 400;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 400;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Poppins;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Poppins;
  --mat-expansion-container-text-line-height: 22px;
  --mat-expansion-container-text-size: 16px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 16px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 16px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: var(--primary);
}
.mat-icon.mat-accent {
  --mat-icon-color: var(--primary);
}
.mat-icon.mat-warn {
  --mat-icon-color: #e91e63;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: var(--primary);
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--primary);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--primary);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #e91e63;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #e91e63;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: var(--primary);
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--primary);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--primary);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #e91e63;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #e91e63;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #e91e63;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Poppins;
  --mat-stepper-header-label-text-font: Poppins;
  --mat-stepper-header-label-text-size: 16px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: var(--primary);
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: var(--primary);
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #e91e63;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Poppins;
  --mat-toolbar-title-text-line-height: 26px;
  --mat-toolbar-title-text-size: 18px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Poppins;
  --mat-tree-node-text-size: 16px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Poppins;
  --mdc-form-field-label-text-line-height: 22px;
  --mdc-form-field-label-text-size: 16px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

.light-theme .blue_theme .mat-mdc-raised-button.mat-primary,
.light-theme .blue_theme .mat-mdc-raised-button.mat-accent,
.light-theme .blue_theme .mat-mdc-raised-button.mat-warn,
.light-theme .blue_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .blue_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .blue_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .blue_theme .mdc-fab.mat-primary,
.light-theme .blue_theme .mdc-fab.mat-accent,
.light-theme .blue_theme .mdc-fab.mat-warn,
.light-theme .purple_theme .mat-mdc-raised-button.mat-primary,
.light-theme .purple_theme .mat-mdc-raised-button.mat-accent,
.light-theme .purple_theme .mat-mdc-raised-button.mat-warn,
.light-theme .purple_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .purple_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .purple_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .purple_theme .mdc-fab.mat-primary,
.light-theme .purple_theme .mdc-fab.mat-accent,
.light-theme .purple_theme .mdc-fab.mat-warn,
.light-theme .orange_theme .mat-mdc-raised-button.mat-primary,
.light-theme .orange_theme .mat-mdc-raised-button.mat-accent,
.light-theme .orange_theme .mat-mdc-raised-button.mat-warn,
.light-theme .orange_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .orange_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .orange_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .orange_theme .mdc-fab.mat-primary,
.light-theme .orange_theme .mdc-fab.mat-accent,
.light-theme .orange_theme .mdc-fab.mat-warn,
.light-theme .green_theme .mat-mdc-raised-button.mat-primary,
.light-theme .green_theme .mat-mdc-raised-button.mat-accent,
.light-theme .green_theme .mat-mdc-raised-button.mat-warn,
.light-theme .green_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .green_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .green_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .green_theme .mdc-fab.mat-primary,
.light-theme .green_theme .mdc-fab.mat-accent,
.light-theme .green_theme .mdc-fab.mat-warn,
.light-theme .aqua_theme .mat-mdc-raised-button.mat-primary,
.light-theme .aqua_theme .mat-mdc-raised-button.mat-accent,
.light-theme .aqua_theme .mat-mdc-raised-button.mat-warn,
.light-theme .aqua_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .aqua_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .aqua_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .aqua_theme .mdc-fab.mat-primary,
.light-theme .aqua_theme .mdc-fab.mat-accent,
.light-theme .aqua_theme .mdc-fab.mat-warn,
.light-theme .cyan_theme .mat-mdc-raised-button.mat-primary,
.light-theme .cyan_theme .mat-mdc-raised-button.mat-accent,
.light-theme .cyan_theme .mat-mdc-raised-button.mat-warn,
.light-theme .cyan_theme .mat-mdc-unelevated-button.mat-primary,
.light-theme .cyan_theme .mat-mdc-unelevated-button.mat-accent,
.light-theme .cyan_theme .mat-mdc-unelevated-button.mat-warn,
.light-theme .cyan_theme .mdc-fab.mat-primary,
.light-theme .cyan_theme .mdc-fab.mat-accent,
.light-theme .cyan_theme .mdc-fab.mat-warn,
.dark-theme .mat-mdc-raised-button.mat-primary,
.dark-theme .mat-mdc-raised-button.mat-accent,
.dark-theme .mat-mdc-raised-button.mat-warn,
.dark-theme .mat-mdc-unelevated-button.mat-primary,
.dark-theme .mat-mdc-unelevated-button.mat-accent,
.dark-theme .mat-mdc-unelevated-button.mat-warn,
.dark-theme .mdc-fab.mat-primary,
.dark-theme .mdc-fab.mat-accent,
.dark-theme .mdc-fab.mat-warn {
  color: white;
}
.light-theme .blue_theme .mdc-button,
.light-theme .purple_theme .mdc-button,
.light-theme .orange_theme .mdc-button,
.light-theme .green_theme .mdc-button,
.light-theme .aqua_theme .mdc-button,
.light-theme .cyan_theme .mdc-button,
.dark-theme .mdc-button {
  border-radius: 7px;
}
.light-theme .blue_theme .mat-mdc-raised-button,
.light-theme .purple_theme .mat-mdc-raised-button,
.light-theme .orange_theme .mat-mdc-raised-button,
.light-theme .green_theme .mat-mdc-raised-button,
.light-theme .aqua_theme .mat-mdc-raised-button,
.light-theme .cyan_theme .mat-mdc-raised-button,
.dark-theme .mat-mdc-raised-button {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}
.light-theme .blue_theme .mat-mdc-fab,
.light-theme .blue_theme .mat-mdc-mini-fab,
.light-theme .blue_theme .mat-mdc-fab:hover,
.light-theme .blue_theme .mat-mdc-fab:focus,
.light-theme .blue_theme .mat-mdc-mini-fab:hover,
.light-theme .blue_theme .mat-mdc-mini-fab:focus,
.light-theme .purple_theme .mat-mdc-fab,
.light-theme .purple_theme .mat-mdc-mini-fab,
.light-theme .purple_theme .mat-mdc-fab:hover,
.light-theme .purple_theme .mat-mdc-fab:focus,
.light-theme .purple_theme .mat-mdc-mini-fab:hover,
.light-theme .purple_theme .mat-mdc-mini-fab:focus,
.light-theme .orange_theme .mat-mdc-fab,
.light-theme .orange_theme .mat-mdc-mini-fab,
.light-theme .orange_theme .mat-mdc-fab:hover,
.light-theme .orange_theme .mat-mdc-fab:focus,
.light-theme .orange_theme .mat-mdc-mini-fab:hover,
.light-theme .orange_theme .mat-mdc-mini-fab:focus,
.light-theme .green_theme .mat-mdc-fab,
.light-theme .green_theme .mat-mdc-mini-fab,
.light-theme .green_theme .mat-mdc-fab:hover,
.light-theme .green_theme .mat-mdc-fab:focus,
.light-theme .green_theme .mat-mdc-mini-fab:hover,
.light-theme .green_theme .mat-mdc-mini-fab:focus,
.light-theme .aqua_theme .mat-mdc-fab,
.light-theme .aqua_theme .mat-mdc-mini-fab,
.light-theme .aqua_theme .mat-mdc-fab:hover,
.light-theme .aqua_theme .mat-mdc-fab:focus,
.light-theme .aqua_theme .mat-mdc-mini-fab:hover,
.light-theme .aqua_theme .mat-mdc-mini-fab:focus,
.light-theme .cyan_theme .mat-mdc-fab,
.light-theme .cyan_theme .mat-mdc-mini-fab,
.light-theme .cyan_theme .mat-mdc-fab:hover,
.light-theme .cyan_theme .mat-mdc-fab:focus,
.light-theme .cyan_theme .mat-mdc-mini-fab:hover,
.light-theme .cyan_theme .mat-mdc-mini-fab:focus,
.dark-theme .mat-mdc-fab,
.dark-theme .mat-mdc-mini-fab,
.dark-theme .mat-mdc-fab:hover,
.dark-theme .mat-mdc-fab:focus,
.dark-theme .mat-mdc-mini-fab:hover,
.dark-theme .mat-mdc-mini-fab:focus {
  box-shadow: none;
}
.light-theme .blue_theme .mat-button-toggle-button,
.light-theme .purple_theme .mat-button-toggle-button,
.light-theme .orange_theme .mat-button-toggle-button,
.light-theme .green_theme .mat-button-toggle-button,
.light-theme .aqua_theme .mat-button-toggle-button,
.light-theme .cyan_theme .mat-button-toggle-button,
.dark-theme .mat-button-toggle-button {
  font-size: 14px;
}
@media (min-width: 768px) {
  .light-theme .blue_theme .flex-sm-row,
  .light-theme .purple_theme .flex-sm-row,
  .light-theme .orange_theme .flex-sm-row,
  .light-theme .green_theme .flex-sm-row,
  .light-theme .aqua_theme .flex-sm-row,
  .light-theme .cyan_theme .flex-sm-row,
  .dark-theme .flex-sm-row {
    flex-direction: row !important;
  }
}
.light-theme .blue_theme .flex-column,
.light-theme .purple_theme .flex-column,
.light-theme .orange_theme .flex-column,
.light-theme .green_theme .flex-column,
.light-theme .aqua_theme .flex-column,
.light-theme .cyan_theme .flex-column,
.dark-theme .flex-column {
  flex-direction: column;
}
.light-theme .blue_theme .mat-drawer,
.light-theme .purple_theme .mat-drawer,
.light-theme .orange_theme .mat-drawer,
.light-theme .green_theme .mat-drawer,
.light-theme .aqua_theme .mat-drawer,
.light-theme .cyan_theme .mat-drawer,
.dark-theme .mat-drawer {
  background-color: white;
}
.light-theme .blue_theme .mat-drawer-container,
.light-theme .purple_theme .mat-drawer-container,
.light-theme .orange_theme .mat-drawer-container,
.light-theme .green_theme .mat-drawer-container,
.light-theme .aqua_theme .mat-drawer-container,
.light-theme .cyan_theme .mat-drawer-container,
.dark-theme .mat-drawer-container {
  background-color: #eef5f9;
}
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__notch {
  border-color: #e5eaef;
}
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.light-theme .blue_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .purple_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .orange_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .green_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .aqua_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .cyan_theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.light-theme .blue_theme .mdc-form-field > label,
.light-theme .purple_theme .mdc-form-field > label,
.light-theme .orange_theme .mdc-form-field > label,
.light-theme .green_theme .mdc-form-field > label,
.light-theme .aqua_theme .mdc-form-field > label,
.light-theme .cyan_theme .mdc-form-field > label,
.dark-theme .mdc-form-field > label {
  font-size: 14px;
}
.light-theme .blue_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .purple_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .orange_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .green_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .aqua_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.light-theme .cyan_theme .hide-hint .mat-mdc-form-field-subscript-wrapper,
.dark-theme .hide-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.light-theme .blue_theme .theme-select,
.light-theme .purple_theme .theme-select,
.light-theme .orange_theme .theme-select,
.light-theme .green_theme .theme-select,
.light-theme .aqua_theme .theme-select,
.light-theme .cyan_theme .theme-select,
.dark-theme .theme-select {
  width: 125px;
  height: 36px;
}
.light-theme .blue_theme .theme-select .mat-form-field-infix,
.light-theme .purple_theme .theme-select .mat-form-field-infix,
.light-theme .orange_theme .theme-select .mat-form-field-infix,
.light-theme .green_theme .theme-select .mat-form-field-infix,
.light-theme .aqua_theme .theme-select .mat-form-field-infix,
.light-theme .cyan_theme .theme-select .mat-form-field-infix,
.dark-theme .theme-select .mat-form-field-infix {
  padding: 6px 0 1px !important;
}
.light-theme .blue_theme .theme-select .mat-mdc-form-field-subscript-wrapper,
.light-theme .purple_theme .theme-select .mat-mdc-form-field-subscript-wrapper,
.light-theme .orange_theme .theme-select .mat-mdc-form-field-subscript-wrapper,
.light-theme .green_theme .theme-select .mat-mdc-form-field-subscript-wrapper,
.light-theme .aqua_theme .theme-select .mat-mdc-form-field-subscript-wrapper,
.light-theme .cyan_theme .theme-select .mat-mdc-form-field-subscript-wrapper,
.dark-theme .theme-select .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.light-theme .blue_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .purple_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .orange_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .green_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .aqua_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.light-theme .cyan_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-color: #e5eaef;
}
.light-theme .blue_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .purple_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .orange_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .green_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .aqua_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.light-theme .cyan_theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.dark-theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-color: #e5eaef;
}
.light-theme .blue_theme .theme-select .mat-mdc-form-field-flex,
.light-theme .purple_theme .theme-select .mat-mdc-form-field-flex,
.light-theme .orange_theme .theme-select .mat-mdc-form-field-flex,
.light-theme .green_theme .theme-select .mat-mdc-form-field-flex,
.light-theme .aqua_theme .theme-select .mat-mdc-form-field-flex,
.light-theme .cyan_theme .theme-select .mat-mdc-form-field-flex,
.dark-theme .theme-select .mat-mdc-form-field-flex {
  height: 36px;
  align-items: center;
}
.light-theme .blue_theme .mdc-checkbox__background,
.light-theme .purple_theme .mdc-checkbox__background,
.light-theme .orange_theme .mdc-checkbox__background,
.light-theme .green_theme .mdc-checkbox__background,
.light-theme .aqua_theme .mdc-checkbox__background,
.light-theme .cyan_theme .mdc-checkbox__background,
.dark-theme .mdc-checkbox__background {
  border: 1px solid #dfe5ef !important;
  width: 19px;
  height: 19px;
  border-radius: 3px;
}
.light-theme .blue_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .purple_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .orange_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .green_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .aqua_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.light-theme .cyan_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
.dark-theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #ffffff !important;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 15px !important;
  height: 15px !important;
  top: unset;
  bottom: unset;
}
.light-theme .blue_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .purple_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .orange_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .green_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .aqua_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.light-theme .cyan_theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark,
.dark-theme .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #ffffff !important;
}
.light-theme .blue_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .purple_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .orange_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .green_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .aqua_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.light-theme .cyan_theme .mat-mdc-checkbox-checked .mdc-checkbox__background,
.dark-theme .mat-mdc-checkbox-checked .mdc-checkbox__background {
  border: 0 !important;
}
.light-theme .blue_theme .mdc-radio__outer-circle,
.light-theme .purple_theme .mdc-radio__outer-circle,
.light-theme .orange_theme .mdc-radio__outer-circle,
.light-theme .green_theme .mdc-radio__outer-circle,
.light-theme .aqua_theme .mdc-radio__outer-circle,
.light-theme .cyan_theme .mdc-radio__outer-circle,
.dark-theme .mdc-radio__outer-circle {
  border-width: 1px;
}
.light-theme .blue_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .purple_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .orange_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .green_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .aqua_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .cyan_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #e5eaef;
}
.light-theme .blue_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .purple_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .orange_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .green_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .aqua_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.light-theme .cyan_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-width: 10px;
}
.light-theme .blue_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .purple_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .orange_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .green_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .aqua_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.light-theme .cyan_theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #ffffff;
}
.light-theme .blue_theme .topbar,
.light-theme .purple_theme .topbar,
.light-theme .orange_theme .topbar,
.light-theme .green_theme .topbar,
.light-theme .aqua_theme .topbar,
.light-theme .cyan_theme .topbar,
.dark-theme .topbar {
  background-color: white;
}

.light-theme .mat-mdc-card,
.dark-theme .mat-mdc-card {
  margin-bottom: 24px;
  border-radius: 7px;
}
.light-theme .mat-mdc-card-header,
.dark-theme .mat-mdc-card-header {
  padding: 24px 24px 0;
}
.light-theme .mat-mdc-card-content,
.dark-theme .mat-mdc-card-content {
  padding: 0 24px;
}
.light-theme .mat-mdc-card,
.dark-theme .mat-mdc-card {
  background-color: white;
}
.light-theme .cardWithShadow,
.dark-theme .cardWithShadow {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}
.light-theme .mat-mdc-card-title,
.dark-theme .mat-mdc-card-title {
  line-height: 1.6rem;
  font-size: 18px;
  font-weight: 500;
  color: #2a3547;
}
.light-theme .mat-mdc-card-subtitle,
.dark-theme .mat-mdc-card-subtitle {
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
  color: #2a3547;
}
.light-theme .mdc-card__actions,
.dark-theme .mdc-card__actions {
  padding: 24px;
}
.light-theme .theme-card.mat-mdc-card .mat-mdc-card-header,
.dark-theme .theme-card.mat-mdc-card .mat-mdc-card-header {
  padding: 16px 24px;
}
.light-theme .theme-card.mat-mdc-card .mat-mdc-card-content,
.dark-theme .theme-card.mat-mdc-card .mat-mdc-card-content {
  padding: 24px;
}
.light-theme .card-hover,
.dark-theme .card-hover {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.light-theme .card-hover:hover,
.dark-theme .card-hover:hover {
  scale: 1.01;
  transition: all 0.1s ease-in 0s;
}
.light-theme html .mat-mdc-chip,
.dark-theme html .mat-mdc-chip {
  height: 24px;
  font-size: 14px;
  background-color: #f2f6fa;
}
.light-theme .cardBorder .mdc-card,
.dark-theme .cardBorder .mdc-card {
  box-shadow: none !important;
  border: 1px solid #e5eaef !important;
}
.light-theme .cardBorder .mdc-card.shadow-none,
.dark-theme .cardBorder .mdc-card.shadow-none {
  border: 0 !important;
}
.light-theme .mat-subtitle-2,
.light-theme .mat-subtitle-1,
.light-theme .mat-headline-5,
.light-theme .mat-headline-6,
.light-theme .mat-subtitle-1,
.light-theme .mat-subtitle-2,
.light-theme .mat-body-1,
.light-theme .mat-body-2,
.dark-theme .mat-subtitle-2,
.dark-theme .mat-subtitle-1,
.dark-theme .mat-headline-5,
.dark-theme .mat-headline-6,
.dark-theme .mat-subtitle-1,
.dark-theme .mat-subtitle-2,
.dark-theme .mat-body-1,
.dark-theme .mat-body-2 {
  color: #2a3547;
}
.light-theme .mat-headline-5,
.dark-theme .mat-headline-5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  font-family: Poppins;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-headline-6,
.dark-theme .mat-headline-6 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  font-family: Poppins;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-subtitle-2,
.dark-theme .mat-subtitle-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  font-family: Poppins;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-subtitle-1,
.dark-theme .mat-subtitle-1 {
  font-size: 21px;
  font-weight: 500;
  line-height: 26px;
  font-family: Poppins;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-body-1,
.dark-theme .mat-body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Poppins;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mat-body-2,
.dark-theme .mat-body-2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: Poppins;
  letter-spacing: normal;
  margin: 0;
  color: #2a3547;
}
.light-theme .mdc-list-item__primary-text,
.light-theme .mat-drawer-container,
.light-theme .mat-drawer,
.dark-theme .mdc-list-item__primary-text,
.dark-theme .mat-drawer-container,
.dark-theme .mat-drawer {
  color: #2a3547;
}

.mat-drawer-side {
  border-right: 1px solid #e5eaef;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.0784313725);
}

.mat-divider {
  border-top-color: #e5eaef;
}

.mat-mdc-progress-bar {
  border-radius: 7px;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 16px;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-color: #e5eaef;
}

.mat-mdc-menu-item:hover:not([disabled]) {
  background: #f6f9fc;
}

.mat-mdc-list-item:hover::before {
  background-color: #f6f9fc !important;
  opacity: 1 !important;
  z-index: -1;
}

mdc-list-item__content {
  z-index: 2;
}

.dark-theme .mat-headline-5,
.dark-theme .mat-subtitle-1,
.dark-theme .mat-headline-6,
.dark-theme .mat-button-toggle-button,
.dark-theme .mat-drawer-container,
.dark-theme .mdc-data-table__header-cell,
.dark-theme .breadcrumb-item a,
.dark-theme .mat-mdc-card-title {
  color: #eaeff4;
}
.dark-theme .mat-subtitle-2,
.dark-theme .mat-body-1,
.dark-theme .mat-body-2,
.dark-theme .ngx-pagination a,
.dark-theme .ngx-pagination button,
.dark-theme .mdc-data-table__pagination-total,
.dark-theme .mdc-data-table__pagination-rows-per-page-label,
.dark-theme .mdc-data-table__cell,
.dark-theme .mat-mdc-icon-button,
.dark-theme .mdc-list-item__secondary-text,
.dark-theme .mat-mdc-card-subtitle {
  color: #7c8fac;
}
.dark-theme .mat-drawer {
  color: #7c8fac !important;
  background-color: #2a3447;
}
.dark-theme .mat-drawer-container {
  background-color: #2a3447;
}
.dark-theme .mdc-list-item:hover::before,
.dark-theme .bg-light {
  background-color: #333f55 !important;
}
.dark-theme .mat-mdc-card,
.dark-theme .mat-mdc-table,
.dark-theme .cal-week-view,
.dark-theme .cal-week-view .cal-hour-odd,
.dark-theme .mat-datepicker-content,
.dark-theme .mat-stepper-horizontal,
.dark-theme .mat-stepper-vertical {
  background-color: #2a3447;
}
.dark-theme .bg-white,
.dark-theme .mdc-menu-surface {
  background-color: #2a3447 !important;
}
.dark-theme .topbar {
  background-color: #2a3447;
}
.dark-theme .cardWithShadow {
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
}
.dark-theme .timeline .timeline-item .timline-border {
  background-color: #333f55;
}
.dark-theme .mat-mdc-paginator,
.dark-theme .todo-item .edit-view,
.dark-theme .mat-button-toggle-appearance-standard,
.dark-theme .mat-tree,
.dark-theme .mat-expansion-panel {
  background: #2a3447 !important;
}
.dark-theme .cal-month-view .cal-cell-row:hover,
.dark-theme .cal-month-view .cal-cell-row .cal-cell:hover,
.dark-theme .cal-month-view .cal-cell.cal-has-events.cal-open,
.dark-theme .cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover,
.dark-theme .cal-week-view .cal-day-headers .cal-header:hover,
.dark-theme .cal-week-view .cal-day-headers .cal-drag-over,
.dark-theme .bg-extra-light {
  background-color: #333f55;
}
.dark-theme .or-border:after,
.dark-theme .or-border:before {
  background: #333f55;
}
.dark-theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .theme-select .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #333f55;
}
.dark-theme .cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #333f55;
}
.dark-theme .cal-month-view .cal-days .cal-cell-row,
.dark-theme .cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.dark-theme .cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: #333f55;
}
.dark-theme .b-1 {
  border: 1px solid #333f55 !important;
}
.dark-theme .mdc-checkbox__background {
  border: 1px solid #465670 !important;
}
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #465670;
}
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__trailing,
.dark-theme .mdc-text-field--outlined .mdc-notched-outline__notch,
.dark-theme .cal-month-view .cal-days,
.dark-theme .cal-week-view,
.dark-theme .cal-week-view .cal-day-headers,
.dark-theme .cal-week-view .cal-time-events,
.dark-theme .cal-week-view .cal-day-column,
.dark-theme .cal-week-view .cal-day-headers .cal-header:first-child,
.dark-theme .cal-week-view .cal-day-headers .cal-header:not(:last-child),
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #465670 !important;
}
.dark-theme .b-b-1 {
  border-bottom: 1px solid #333f55 !important;
}
.dark-theme .b-t-1 {
  border-top: 1px solid #333f55 !important;
}
.dark-theme .b-r-1 {
  border-right: 1px solid #333f55 !important;
}
.dark-theme .b-l-1 {
  border-left: 1px solid #333f55;
}
.dark-theme .bg-light-primary {
  background-color: #253662 !important;
}
.dark-theme .bg-light-accent {
  background-color: #1C455D !important;
}
.dark-theme .bg-light-error {
  background-color: #4B313D !important;
}
.dark-theme .bg-light-success {
  background-color: #1B3C48 !important;
}
.dark-theme .bg-light-warning {
  background-color: #4D3A2A !important;
}
.dark-theme .todos .taskboard-task {
  background: #253662 !important;
}
.dark-theme .inprogress .taskboard-task {
  background: #4D3A2A !important;
}
.dark-theme .completed .taskboard-task {
  background: #1B3C48 !important;
}
.dark-theme .onhold .taskboard-task {
  background: #4B313D !important;
}
.dark-theme #vector-map .dxm-layer path {
  fill: #253662;
}
.dark-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #2a3447;
}
.dark-theme .customizer-button-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked i-tabler.fill-icon {
  fill: #253662;
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #333f55;
}
.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #333f55;
}
.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #333f55;
}
.dark-theme .mat-mdc-button,
.dark-theme .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #7c8fac !important;
}
.dark-theme .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #7c8fac !important;
}
.dark-theme .mdc-list-item__primary-text {
  color: #7c8fac !important;
}
.dark-theme .mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: #7c8fac !important;
}
.dark-theme .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87) !important;
}
.dark-theme .mat-mdc-select-arrow svg {
  fill: rgba(255, 255, 255, 0.87) !important;
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.6) !important;
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label .mat-mdc-icon-button[disabled][disabled],
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5) !important;
  --mdc-icon-button-disabled-icon-color: rgba(
    255,
    255,
    255,
    0.5
  ) !important;
}

html.dark-theme .cardBorder .mdc-card {
  box-shadow: none !important;
  border: 1px solid #333f55 !important;
}
html.dark-theme .cardBorder .mdc-card.shadow-none {
  border: 0 !important;
}
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .activeMenu,
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox .activeMenu {
  color: #ffffff !important;
}
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox .menuLink {
  color: #7c8fac;
}
html.dark-theme .sidebarNav-horizontal .horizontal-navbar .parentBox .childBox {
  background-color: #2a3447;
}

html.dark-theme .bg-gredient::before {
  opacity: 0.05;
}
html.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
html.dark-theme .mdc-text-field__input::placeholder,
html.dark-theme .mat-mdc-tab .mdc-tab__text-label,
html.dark-theme .mat-mdc-tab-link .mdc-tab__text-label,
html.dark-theme .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label,
html.dark-theme .mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.6) !important;
}
html.dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
html.dark-theme .mat-expansion-panel-header-description,
html.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
html.dark-theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
html.dark-theme .mat-calendar-table-header,
html.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
html.dark-theme .mat-calendar-arrow {
  fill: white;
}
html.dark-theme .mat-mdc-radio-button .mdc-form-field,
html.dark-theme .mat-calendar-body-cell-content,
html.dark-theme .mat-date-range-input-separator,
html.dark-theme .mat-expansion-panel-header-title,
html.dark-theme .mat-step-header .mat-step-label.mat-step-label-active,
html.dark-theme .mat-expansion-panel-header-title {
  color: white;
}
html.dark-theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
html.dark-theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
html.dark-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
html.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-label-text-color: #fff;
}
html.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-label-text-color: #fff;
}
html.dark-theme .mdc-data-table__cell,
html.dark-theme .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
html.dark-theme .mat-mdc-checkbox .mdc-form-field,
html.dark-theme .mat-mdc-paginator,
html.dark-theme .mat-mdc-slide-toggle .mdc-form-field {
  color: rgba(255, 255, 255, 0.87) !important;
}
html.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
html.dark-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #333f55;
}
html.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #333f55;
}
html.dark-theme .bg-primary {
  background-color: var(--primary) !important;
}
html.dark-theme .text-primary {
  color: var(--primary) !important;
}
html.dark-theme .bg-accent {
  background-color: var(--accent) !important;
}
html.dark-theme .text-accent {
  color: var(--accent) !important;
}
html.dark-theme .bg-success {
  background-color: #2AD0B6 !important;
}
html.dark-theme .text-success {
  color: #2AD0B6 !important;
}
html.dark-theme .bg-warning {
  background-color: #ffb22b !important;
}
html.dark-theme .text-warning {
  color: #ffb22b !important;
}
html.dark-theme .bg-error {
  background-color: #fc4b6c !important;
}
html.dark-theme .text-error {
  color: #fc4b6c !important;
}

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  color: #2a3547;
  font-size: 10px;
  line-height: 1.334rem;
}

html .mat-drawer-container {
  background-color: #ffffff;
}

.mainWrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex: 1;
  height: 100%;
}

.pageWrapper {
  padding: 40px;
  min-height: calc(100vh - 70px);
  margin: 0 auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.shadow-none {
  box-shadow: none !important;
}

.rounded {
  border-radius: 7px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 25px !important;
}

.overflow-hidden {
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none;
}

.position-relative {
  position: relative;
}

.table-responsive {
  overflow-x: auto;
  max-height: 60vh;
  overflow-y: auto;
}
.table-responsive td,
.table-responsive mat-cell {
  white-space: nowrap;
  padding: 16px;
}

@media (max-width: 991px) {
  .table-responsive-sm {
    overflow-x: auto;
  }
}
.op-5 {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.avatar-group img {
  border: 2px solid white;
  margin-right: -5px;
}
.avatar-group img:last-child {
  margin-right: 0;
}

.text-hover-primary:hover .hover-text {
  color: var(--primary);
}

.filter-sidebar {
  width: 290px;
}

.sidebarNav {
  width: 270px;
  flex-shrink: 0;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  position: fixed !important;
  top: 0 !important;
  overflow-x: hidden;
}
.sidebarNav .mat-drawer-inner-container {
  overflow: hidden;
}

.user-profile {
  position: relative;
  background-size: cover !important;
}
.user-profile .profile-img {
  width: 50px;
  margin-left: 30px;
  padding: 31px 0;
  border-radius: 100%;
}
.user-profile .profile-img::before {
  -webkit-animation: 2.5s blow 0s linear infinite;
  animation: 2.5s blow 0s linear infinite;
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  top: 31px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 0;
}
.user-profile .profile-img img {
  width: 100%;
  border-radius: 100%;
}
.user-profile .profile-text {
  position: relative;
  cursor: pointer;
}
.user-profile .profile-text > a {
  padding: 6px 30px;
  background: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
}
.user-profile .profile-text > a:after {
  position: absolute;
  right: 20px;
  top: 20px;
}

.profile-dd {
  margin-left: 26px !important;
}

.sidebarNav-mini .mini-scroll {
  height: calc(100vh - 70px) !important;
}
.sidebarNav-mini .branding {
  justify-content: center;
}
.sidebarNav-mini .branding a {
  width: 45px;
  overflow: hidden;
}
.sidebarNav-mini .sidebarNav {
  width: 80px;
}
.sidebarNav-mini .sidebarNav .profile-bar {
  display: none;
}
.sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item {
  padding: 8px 17px;
}
.sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item .mdc-list-item__content {
  display: none;
}
.sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item .mdc-list-item__start {
  margin-left: 6px !important;
  margin-right: 8px !important;
}
.sidebarNav-mini .hide-menu {
  overflow: hidden;
  width: 80px;
}
.sidebarNav-mini .sidebar-list.mdc-list {
  padding: 0 12px;
}
.sidebarNav-mini .sidebar-list.mdc-list .mdc-list-group__subheader {
  text-align: center;
}
.sidebarNav-mini .contentWrapper {
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (min-width: 1024px) {
  .sidebarNav-mini .contentWrapper {
    margin-left: 80px !important;
  }
}
.customizerBtn {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 9;
}

.sidebar-list.mdc-list {
  padding: 0 24px;
}
.sidebar-list.mdc-list .mdc-list-group__subheader {
  margin: 12px 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 24px;
}
.sidebar-list.mdc-list .menu-list-item {
  border-radius: 7px;
  height: 45px;
  padding: 8px 10px !important;
  margin-bottom: 2px;
}
.sidebar-list.mdc-list .menu-list-item.twoline {
  height: 60px;
  align-items: center;
}
.sidebar-list.mdc-list .menu-list-item:before, .sidebar-list.mdc-list .menu-list-item:focus {
  z-index: -1;
}
.sidebar-list.mdc-list .menu-list-item.disabled {
  opacity: 0.38;
}
.sidebar-list.mdc-list .menu-list-item .item-chip {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.sidebar-list.mdc-list .menu-list-item.activeMenu .mdc-list-item__primary-text {
  color: #ffffff !important;
}
.sidebar-list.mdc-list .menu-list-item.activeMenu .mat-mdc-list-item-icon {
  color: #ffffff !important;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__start {
  margin-right: 14px;
  margin-left: 0 !important;
  width: 20px;
  height: 20px;
  fill: transparent !important;
  color: #2a3547;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__primary-text .arrow-icon {
  display: flex;
}
.sidebar-list.mdc-list .menu-list-item .mdc-list-item__primary-text .arrow-icon .mat-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@keyframes blow {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}
.branding {
  width: 270px;
  padding: 15px 24px 10px 24px;
}

.sidebarNav-mini .branding {
  width: 65px;
  overflow: hidden;
}

html .topbar {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
  height: 70px;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.5019607843);
}

.topbar-dd {
  min-width: 360px !important;
}
.topbar-dd.mat-mdc-menu-panel.mat-mdc-menu-panel {
  overflow: unset;
}

.apps-dd {
  min-width: 830px !important;
  overflow: unset !important;
}
.apps-dd .mat-mdc-menu-content {
  padding: 0;
}

.upgrade-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
}

.object-cover {
  object-fit: cover;
}

.header-badge.mat-badge-medium .mat-badge-content {
  width: 18px;
  height: 18px;
  font-size: 10px;
  line-height: 18px;
  right: -15px;
}

@media (min-width: 768px) {
  .search-dialog {
    width: 600px;
  }
}
.ps__rail-y {
  right: 0;
  left: unset !important;
}

.fixed-customizer {
  position: fixed !important;
  z-index: 9 !important;
}

.customizer-button-group.two-row {
  display: inline-block;
}
.customizer-button-group.two-row.theme-colors .mat-button-toggle-appearance-standard {
  margin-left: 16px;
}
.customizer-button-group.two-row.theme-colors .mat-button-toggle-appearance-standard:first-child {
  margin-left: 0;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard {
  width: 80px;
  height: 65px;
  float: left;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .mat-button-toggle-button {
  display: flex;
  justify-content: center;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard:nth-child(4) {
  margin-left: 0;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard.mat-button-toggle-checked .theme-circle .theme-icon {
  display: flex;
  color: white;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle .theme-icon {
  display: none;
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.orange_theme {
  background-color: rgb(250, 137, 107);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.blue_theme {
  background-color: rgb(30, 136, 229);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.aqua_theme {
  background-color: rgb(0, 116, 186);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.purple_theme {
  background-color: rgb(118, 62, 189);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.green_theme {
  background-color: rgb(10, 126, 164);
}
.customizer-button-group.two-row .mat-button-toggle-appearance-standard .theme-circle.cyan_theme {
  background-color: rgb(1, 192, 200);
}
.customizer-button-group .mat-button-toggle-appearance-standard {
  padding: 9px;
  border: 0;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  border-left: 0 !important;
  transition: all 0.1s ease-in 0s;
}
.customizer-button-group .mat-button-toggle-appearance-standard:hover {
  transform: scale(1.05);
}
.customizer-button-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: transparent;
  color: #2a3547;
}
.customizer-button-group .mat-button-toggle-appearance-standard.mat-button-toggle-checked i-tabler.fill-icon {
  color: var(--primary);
  fill: #ecf6ff;
}
.customizer-button-group.mat-button-toggle-group {
  overflow: unset;
  border-radius: 0;
}
.customizer-button-group .mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .customizer-button-group.mat-button-toggle-group-appearance-standard {
  border: 0 !important;
}

.breadcrumb-item:first-child:after {
  content: ">";
  margin-left: 15px;
}
.breadcrumb-item.active {
  margin-left: 20px;
}
.breadcrumb-item a {
  text-decoration: none;
  color: #2a3547;
  font-size: 14px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-sm-12 {
    margin-left: 100%;
  }
}
@media (min-width: 960px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-md-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-lg-12 {
    margin-left: 100%;
  }
}
@media (min-width: 2100px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offset-xl-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  body .d-lg-flex {
    display: flex !important;
  }
  body .d-lg-none {
    display: none !important;
  }
  body .align-items-lg-center {
    align-items: center !important;
  }
}
@media (min-width: 768px) {
  body .d-sm-flex {
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .p-xs-6 {
    padding: 0 6px !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-1-auto {
  flex: 1 1 0%;
}

.list-style-none {
  list-style: none;
}

.lh-lg {
  line-height: 36px !important;
}

.lh-sm {
  line-height: 20px !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.m-0 {
  margin: 0 !important;
}

.m-2 {
  margin: 2px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-6 {
  margin: 7px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-32 {
  margin: 32px !important;
}

.m-48 {
  margin: 48px !important;
}

.m-auto {
  margin: auto !important;
}

.m-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-x-2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.m-x-4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.m-x-6 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.m-x-8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.m-x-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.m-x-12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.m-x-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-x-16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.m-x-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-x-24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.m-x-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-x-32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.m-x-48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.m-x-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-y-2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.m-y-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-y-6 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.m-y-8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-y-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-y-12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.m-y-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-y-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-y-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-y-24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-y-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-y-32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m-y-48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.m-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-t-4 {
  margin-top: 4px !important;
}

.m-t-6 {
  margin-top: 7px !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-24 {
  margin-top: 24px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-32 {
  margin-top: 32px !important;
}

.m-t-48 {
  margin-top: 48px !important;
}

.m-t-auto {
  margin-top: auto !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-2 {
  margin-bottom: 2px !important;
}

.m-b-4 {
  margin-bottom: 4px !important;
}

.m-b-6 {
  margin-bottom: 7px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-24 {
  margin-bottom: 24px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-32 {
  margin-bottom: 32px !important;
}

.m-b-48 {
  margin-bottom: 48px !important;
}

.m-b-auto {
  margin-bottom: auto !important;
}

.m--2 {
  margin: -2px !important;
}

.m--4 {
  margin: -4px !important;
}

.m--6 {
  margin: -7px !important;
}

.m--8 {
  margin: -8px !important;
}

.m--10 {
  margin: -10px !important;
}

.m--12 {
  margin: -12px !important;
}

.m--15 {
  margin: -15px !important;
}

.m--16 {
  margin: -16px !important;
}

.m--20 {
  margin: -20px !important;
}

.m--24 {
  margin: -24px !important;
}

.m--30 {
  margin: -30px !important;
}

.m--32 {
  margin: -32px !important;
}

.m--48 {
  margin: -48px !important;
}

.m-x--2 {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.m-x--4 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.m-x--6 {
  margin-left: -7px !important;
  margin-right: -7px !important;
}

.m-x--8 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.m-x--10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.m-x--12 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.m-x--15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.m-x--16 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.m-x--20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.m-x--24 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.m-x--30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.m-x--32 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.m-x--48 {
  margin-left: -48px !important;
  margin-right: -48px !important;
}

.m-y--2 {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

.m-y--4 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.m-y--6 {
  margin-top: -7px !important;
  margin-bottom: -7px !important;
}

.m-y--8 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.m-y--10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.m-y--12 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.m-y--15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.m-y--16 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.m-y--20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.m-y--24 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.m-y--30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.m-y--32 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.m-y--48 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.m-t--2 {
  margin-top: -2px !important;
}

.m-t--4 {
  margin-top: -4px !important;
}

.m-t--6 {
  margin-top: -7px !important;
}

.m-t--8 {
  margin-top: -8px !important;
}

.m-t--10 {
  margin-top: -10px !important;
}

.m-t--12 {
  margin-top: -12px !important;
}

.m-t--15 {
  margin-top: -15px !important;
}

.m-t--16 {
  margin-top: -16px !important;
}

.m-t--20 {
  margin-top: -20px !important;
}

.m-t--24 {
  margin-top: -24px !important;
}

.m-t--30 {
  margin-top: -30px !important;
}

.m-t--32 {
  margin-top: -32px !important;
}

.m-t--48 {
  margin-top: -48px !important;
}

.m-r--2 {
  margin-right: -2px !important;
}

.m-r--4 {
  margin-right: -4px !important;
}

.m-r--6 {
  margin-right: -7px !important;
}

.m-r--8 {
  margin-right: -8px !important;
}

.m-r--10 {
  margin-right: -10px !important;
}

.m-r--12 {
  margin-right: -12px !important;
}

.m-r--15 {
  margin-right: -15px !important;
}

.m-r--16 {
  margin-right: -16px !important;
}

.m-r--20 {
  margin-right: -20px !important;
}

.m-r--24 {
  margin-right: -24px !important;
}

.m-r--30 {
  margin-right: -30px !important;
}

.m-r--32 {
  margin-right: -32px !important;
}

.m-r--48 {
  margin-right: -48px !important;
}

.m-b--2 {
  margin-bottom: -2px !important;
}

.m-b--4 {
  margin-bottom: -4px !important;
}

.m-b--6 {
  margin-bottom: -7px !important;
}

.m-b--8 {
  margin-bottom: -8px !important;
}

.m-b--10 {
  margin-bottom: -10px !important;
}

.m-b--12 {
  margin-bottom: -12px !important;
}

.m-b--15 {
  margin-bottom: -15px !important;
}

.m-b--16 {
  margin-bottom: -16px !important;
}

.m-b--20 {
  margin-bottom: -20px !important;
}

.m-b--24 {
  margin-bottom: -24px !important;
}

.m-b--30 {
  margin-bottom: -30px !important;
}

.m-b--32 {
  margin-bottom: -32px !important;
}

.m-b--48 {
  margin-bottom: -48px !important;
}

.m-l--2 {
  margin-left: -2px !important;
}

.m-l--4 {
  margin-left: -4px !important;
}

.m-l--6 {
  margin-left: -7px !important;
}

.m-l--8 {
  margin-left: -8px !important;
}

.m-l--10 {
  margin-left: -10px !important;
}

.m-l--12 {
  margin-left: -12px !important;
}

.m-l--15 {
  margin-left: -15px !important;
}

.m-l--16 {
  margin-left: -16px !important;
}

.m-l--20 {
  margin-left: -20px !important;
}

.m-l--24 {
  margin-left: -24px !important;
}

.m-l--30 {
  margin-left: -30px !important;
}

.m-l--32 {
  margin-left: -32px !important;
}

.m-l--48 {
  margin-left: -48px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 2px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-6 {
  padding: 7px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-32 {
  padding: 32px !important;
}

.p-48 {
  padding: 48px !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-x-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.p-x-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.p-x-6 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.p-x-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.p-x-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-x-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.p-x-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-x-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.p-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-x-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.p-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-x-32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.p-x-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-y-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p-y-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-y-6 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.p-y-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-y-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.p-y-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-y-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p-y-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-y-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p-y-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-y-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p-y-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-2 {
  padding-top: 2px !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-t-6 {
  padding-top: 7px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-16 {
  padding-top: 16px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-32 {
  padding-top: 32px !important;
}

.p-t-48 {
  padding-top: 48px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-2 {
  padding-bottom: 2px !important;
}

.p-b-4 {
  padding-bottom: 4px !important;
}

.p-b-6 {
  padding-bottom: 7px !important;
}

.p-b-8 {
  padding-bottom: 8px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-12 {
  padding-bottom: 12px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-16 {
  padding-bottom: 16px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-24 {
  padding-bottom: 24px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-32 {
  padding-bottom: 32px !important;
}

.p-b-48 {
  padding-bottom: 48px !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-2 {
  gap: 2px !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-6 {
  gap: 7px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-15 {
  gap: 15px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-24 {
  gap: 24px !important;
}

.gap-30 {
  gap: 30px !important;
}

.gap-32 {
  gap: 32px !important;
}

.gap-48 {
  gap: 48px !important;
}

.b-0 {
  border: 0 !important;
}

.b-1 {
  border: 1px solid #e5eaef !important;
}

.b-2 {
  border: 2px solid #e5eaef !important;
}

.b-4 {
  border: 4px solid #e5eaef !important;
}

.b-8 {
  border: 8px solid #e5eaef !important;
}

.b-t-0 {
  border-top: 0 !important;
}

.b-t-1 {
  border-top: 1px solid #e5eaef !important;
}

.b-t-2 {
  border-top: 2px solid #e5eaef !important;
}

.b-t-4 {
  border-top: 4px solid #e5eaef !important;
}

.b-t-8 {
  border-top: 8px solid #e5eaef !important;
}

.b-b-0 {
  border-bottom: 0 !important;
}

.b-b-1 {
  border-bottom: 1px solid #e5eaef !important;
}

.b-b-2 {
  border-bottom: 2px solid #e5eaef !important;
}

.b-b-4 {
  border-bottom: 4px solid #e5eaef !important;
}

.b-b-8 {
  border-bottom: 8px solid #e5eaef !important;
}

.b-l-0 {
  border-left: 0 !important;
}

.b-l-1 {
  border-left: 1px solid #e5eaef !important;
}

.b-l-2 {
  border-left: 2px solid #e5eaef !important;
}

.b-l-4 {
  border-left: 4px solid #e5eaef !important;
}

.b-l-8 {
  border-left: 8px solid #e5eaef !important;
}

.b-r-0 {
  border-right: 0 !important;
}

.b-r-1 {
  border-right: 1px solid #e5eaef !important;
}

.b-r-2 {
  border-right: 2px solid #e5eaef !important;
}

.b-r-4 {
  border-right: 4px solid #e5eaef !important;
}

.b-r-8 {
  border-right: 8px solid #e5eaef !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-7 {
  border-radius: 7px !important;
}

.rounded-8 {
  border-radius: 14px !important;
}

.rounded-12 {
  border-radius: 21px !important;
}

.rounded-16 {
  border-radius: 28px !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.r-t-l-0 {
  border-top-left-radius: 0 !important;
}

.r-t-l-7 {
  border-top-left-radius: 7px !important;
}

.r-t-l-8 {
  border-top-left-radius: 14px !important;
}

.r-t-l-12 {
  border-top-left-radius: 21px !important;
}

.r-t-l-16 {
  border-top-left-radius: 28px !important;
}

.r-t-l-full {
  border-top-left-radius: 9999px !important;
}

.r-t-r-0 {
  border-top-right-radius: 0 !important;
}

.r-t-r-7 {
  border-top-right-radius: 7px !important;
}

.r-t-r-8 {
  border-top-right-radius: 14px !important;
}

.r-t-r-12 {
  border-top-right-radius: 21px !important;
}

.r-t-r-16 {
  border-top-right-radius: 28px !important;
}

.r-t-r-full {
  border-top-right-radius: 9999px !important;
}

.r-b-r-0 {
  border-bottom-right-radius: 0 !important;
}

.r-b-r-7 {
  border-bottom-right-radius: 7px !important;
}

.r-b-r-8 {
  border-bottom-right-radius: 14px !important;
}

.r-b-r-12 {
  border-bottom-right-radius: 21px !important;
}

.r-b-r-16 {
  border-bottom-right-radius: 28px !important;
}

.r-b-r-full {
  border-bottom-right-radius: 9999px !important;
}

.r-b-l-0 {
  border-bottom-left-radius: 0 !important;
}

.r-b-l-7 {
  border-bottom-left-radius: 7px !important;
}

.r-b-l-8 {
  border-bottom-left-radius: 14px !important;
}

.r-b-l-12 {
  border-bottom-left-radius: 21px !important;
}

.r-b-l-16 {
  border-bottom-left-radius: 28px !important;
}

.r-b-l-full {
  border-bottom-left-radius: 9999px !important;
}

.r-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.r-t-7 {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.r-t-8 {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
}

.r-t-12 {
  border-top-left-radius: 21px !important;
  border-top-right-radius: 21px !important;
}

.r-t-16 {
  border-top-left-radius: 28px !important;
  border-top-right-radius: 28px !important;
}

.r-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.r-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.r-b-7 {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.r-b-8 {
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.r-b-12 {
  border-bottom-left-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.r-b-16 {
  border-bottom-left-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.r-b-full {
  border-bottom-left-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.r-l-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.r-l-7 {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.r-l-8 {
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 14px !important;
}

.r-l-12 {
  border-top-left-radius: 21px !important;
  border-bottom-left-radius: 21px !important;
}

.r-l-16 {
  border-top-left-radius: 28px !important;
  border-bottom-left-radius: 28px !important;
}

.r-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.r-r-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.r-r-7 {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.r-r-8 {
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}

.r-r-12 {
  border-top-right-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.r-r-16 {
  border-top-right-radius: 28px !important;
  border-bottom-right-radius: 28px !important;
}

.r-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.f-s-0 {
  font-size: 0 !important;
}

.f-s-10 {
  font-size: 10px !important;
}

.f-s-12 {
  font-size: 12px !important;
}

.f-s-14 {
  font-size: 14px !important;
}

.f-s-15 {
  font-size: 15px !important;
}

.f-s-16 {
  font-size: 16px !important;
}

.f-s-18 {
  font-size: 18px !important;
}

.f-s-20 {
  font-size: 20px !important;
}

.f-s-24 {
  font-size: 24px !important;
}

.f-s-30 {
  font-size: 30px !important;
}

.f-s-36 {
  font-size: 36px !important;
}

.f-w-100 {
  font-weight: 100 !important;
}

.f-w-200 {
  font-weight: 200 !important;
}

.f-w-300 {
  font-weight: 300 !important;
}

.f-w-400 {
  font-weight: 400 !important;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-700 {
  font-weight: 700 !important;
}

.f-w-800 {
  font-weight: 800 !important;
}

.f-w-900 {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.font-normal {
  font-style: normal !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-none {
  text-decoration: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.text-reset {
  color: inherit !important;
}

.text-current {
  color: currentColor !important;
}

[dir=ltr] .m-r-0 {
  margin-right: 0 !important;
}

[dir=ltr] .m-r-2 {
  margin-right: 2px !important;
}

[dir=ltr] .m-r-4 {
  margin-right: 4px !important;
}

[dir=ltr] .m-r-6 {
  margin-right: 7px !important;
}

[dir=ltr] .m-r-8 {
  margin-right: 8px !important;
}

[dir=ltr] .m-r-10 {
  margin-right: 10px !important;
}

[dir=ltr] .m-r-12 {
  margin-right: 12px !important;
}

[dir=ltr] .m-r-15 {
  margin-right: 15px !important;
}

[dir=ltr] .m-r-16 {
  margin-right: 16px !important;
}

[dir=ltr] .m-r-20 {
  margin-right: 20px !important;
}

[dir=ltr] .m-r-24 {
  margin-right: 24px !important;
}

[dir=ltr] .m-r-30 {
  margin-right: 30px !important;
}

[dir=ltr] .m-r-32 {
  margin-right: 32px !important;
}

[dir=ltr] .m-r-48 {
  margin-right: 48px !important;
}

[dir=ltr] .m-r-auto {
  margin-right: auto !important;
}

[dir=ltr] .m-l-0 {
  margin-left: 0 !important;
}

[dir=ltr] .m-l-2 {
  margin-left: 2px !important;
}

[dir=ltr] .m-l-4 {
  margin-left: 4px !important;
}

[dir=ltr] .m-l-6 {
  margin-left: 7px !important;
}

[dir=ltr] .m-l-8 {
  margin-left: 8px !important;
}

[dir=ltr] .m-l-10 {
  margin-left: 10px !important;
}

[dir=ltr] .m-l-12 {
  margin-left: 12px !important;
}

[dir=ltr] .m-l-15 {
  margin-left: 15px !important;
}

[dir=ltr] .m-l-16 {
  margin-left: 16px !important;
}

[dir=ltr] .m-l-20 {
  margin-left: 20px !important;
}

[dir=ltr] .m-l-24 {
  margin-left: 24px !important;
}

[dir=ltr] .m-l-30 {
  margin-left: 30px !important;
}

[dir=ltr] .m-l-32 {
  margin-left: 32px !important;
}

[dir=ltr] .m-l-48 {
  margin-left: 48px !important;
}

[dir=ltr] .m-l-auto {
  margin-left: auto !important;
}

[dir=ltr] .p-l-0 {
  padding-left: 0 !important;
}

[dir=ltr] .p-l-2 {
  padding-left: 2px !important;
}

[dir=ltr] .p-l-4 {
  padding-left: 4px !important;
}

[dir=ltr] .p-l-6 {
  padding-left: 7px !important;
}

[dir=ltr] .p-l-8 {
  padding-left: 8px !important;
}

[dir=ltr] .p-l-10 {
  padding-left: 10px !important;
}

[dir=ltr] .p-l-12 {
  padding-left: 12px !important;
}

[dir=ltr] .p-l-15 {
  padding-left: 15px !important;
}

[dir=ltr] .p-l-16 {
  padding-left: 16px !important;
}

[dir=ltr] .p-l-20 {
  padding-left: 20px !important;
}

[dir=ltr] .p-l-24 {
  padding-left: 24px !important;
}

[dir=ltr] .p-l-30 {
  padding-left: 30px !important;
}

[dir=ltr] .p-l-32 {
  padding-left: 32px !important;
}

[dir=ltr] .p-l-48 {
  padding-left: 48px !important;
}

[dir=ltr] .p-r-0 {
  padding-right: 0 !important;
}

[dir=ltr] .p-r-2 {
  padding-right: 2px !important;
}

[dir=ltr] .p-r-4 {
  padding-right: 4px !important;
}

[dir=ltr] .p-r-6 {
  padding-right: 7px !important;
}

[dir=ltr] .p-r-8 {
  padding-right: 8px !important;
}

[dir=ltr] .p-r-10 {
  padding-right: 10px !important;
}

[dir=ltr] .p-r-12 {
  padding-right: 12px !important;
}

[dir=ltr] .p-r-15 {
  padding-right: 15px !important;
}

[dir=ltr] .p-r-16 {
  padding-right: 16px !important;
}

[dir=ltr] .p-r-20 {
  padding-right: 20px !important;
}

[dir=ltr] .p-r-24 {
  padding-right: 24px !important;
}

[dir=ltr] .p-r-30 {
  padding-right: 30px !important;
}

[dir=ltr] .p-r-32 {
  padding-right: 32px !important;
}

[dir=ltr] .p-r-48 {
  padding-right: 48px !important;
}

[dir=rtl] .m-r-0 {
  margin-left: 0 !important;
}

[dir=rtl] .m-r-2 {
  margin-left: 2px !important;
}

[dir=rtl] .m-r-4 {
  margin-left: 4px !important;
}

[dir=rtl] .m-r-6 {
  margin-left: 7px !important;
}

[dir=rtl] .m-r-8 {
  margin-left: 8px !important;
}

[dir=rtl] .m-r-10 {
  margin-left: 10px !important;
}

[dir=rtl] .m-r-12 {
  margin-left: 12px !important;
}

[dir=rtl] .m-r-15 {
  margin-left: 15px !important;
}

[dir=rtl] .m-r-16 {
  margin-left: 16px !important;
}

[dir=rtl] .m-r-20 {
  margin-left: 20px !important;
}

[dir=rtl] .m-r-24 {
  margin-left: 24px !important;
}

[dir=rtl] .m-r-30 {
  margin-left: 30px !important;
}

[dir=rtl] .m-r-32 {
  margin-left: 32px !important;
}

[dir=rtl] .m-r-48 {
  margin-left: 48px !important;
}

[dir=rtl] .m-r-auto {
  margin-left: auto !important;
}

[dir=rtl] .m-l-0 {
  margin-right: 0 !important;
}

[dir=rtl] .m-l-2 {
  margin-right: 2px !important;
}

[dir=rtl] .m-l-4 {
  margin-right: 4px !important;
}

[dir=rtl] .m-l-6 {
  margin-right: 7px !important;
}

[dir=rtl] .m-l-8 {
  margin-right: 8px !important;
}

[dir=rtl] .m-l-10 {
  margin-right: 10px !important;
}

[dir=rtl] .m-l-12 {
  margin-right: 12px !important;
}

[dir=rtl] .m-l-15 {
  margin-right: 15px !important;
}

[dir=rtl] .m-l-16 {
  margin-right: 16px !important;
}

[dir=rtl] .m-l-20 {
  margin-right: 20px !important;
}

[dir=rtl] .m-l-24 {
  margin-right: 24px !important;
}

[dir=rtl] .m-l-30 {
  margin-right: 30px !important;
}

[dir=rtl] .m-l-32 {
  margin-right: 32px !important;
}

[dir=rtl] .m-l-48 {
  margin-right: 48px !important;
}

[dir=rtl] .m-l-auto {
  margin-right: auto !important;
}

[dir=rtl] .p-l-0 {
  padding-right: 0 !important;
}

[dir=rtl] .p-l-2 {
  padding-right: 2px !important;
}

[dir=rtl] .p-l-4 {
  padding-right: 4px !important;
}

[dir=rtl] .p-l-6 {
  padding-right: 7px !important;
}

[dir=rtl] .p-l-8 {
  padding-right: 8px !important;
}

[dir=rtl] .p-l-10 {
  padding-right: 10px !important;
}

[dir=rtl] .p-l-12 {
  padding-right: 12px !important;
}

[dir=rtl] .p-l-15 {
  padding-right: 15px !important;
}

[dir=rtl] .p-l-16 {
  padding-right: 16px !important;
}

[dir=rtl] .p-l-20 {
  padding-right: 20px !important;
}

[dir=rtl] .p-l-24 {
  padding-right: 24px !important;
}

[dir=rtl] .p-l-30 {
  padding-right: 30px !important;
}

[dir=rtl] .p-l-32 {
  padding-right: 32px !important;
}

[dir=rtl] .p-l-48 {
  padding-right: 48px !important;
}

[dir=rtl] .p-r-0 {
  padding-left: 0 !important;
}

[dir=rtl] .p-r-2 {
  padding-left: 2px !important;
}

[dir=rtl] .p-r-4 {
  padding-left: 4px !important;
}

[dir=rtl] .p-r-6 {
  padding-left: 7px !important;
}

[dir=rtl] .p-r-8 {
  padding-left: 8px !important;
}

[dir=rtl] .p-r-10 {
  padding-left: 10px !important;
}

[dir=rtl] .p-r-12 {
  padding-left: 12px !important;
}

[dir=rtl] .p-r-15 {
  padding-left: 15px !important;
}

[dir=rtl] .p-r-16 {
  padding-left: 16px !important;
}

[dir=rtl] .p-r-20 {
  padding-left: 20px !important;
}

[dir=rtl] .p-r-24 {
  padding-left: 24px !important;
}

[dir=rtl] .p-r-30 {
  padding-left: 30px !important;
}

[dir=rtl] .p-r-32 {
  padding-left: 32px !important;
}

[dir=rtl] .p-r-48 {
  padding-left: 48px !important;
}

.sidebarNav-horizontal .childBox {
  background: #ffffff;
}
.sidebarNav-horizontal .horizontal-topbar {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 9px 17.5px;
}
.sidebarNav-horizontal .horizontal-topbar .branding {
  padding-left: 0;
}
.sidebarNav-horizontal .horizontal-topbar .container {
  max-width: 1200px;
  display: flex;
  align-items: center;
  width: 100%;
}
.sidebarNav-horizontal .horizontal-navbar {
  position: relative;
  gap: 3px !important;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox {
  position: relative;
  z-index: 5;
  margin: 5px 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu {
  position: static;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu:hover > .childBox > .ddmenu {
  display: inline-block;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu > .childBox {
  width: 100%;
  left: 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.mega-menu > .childBox > .ddmenu {
  width: 24%;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.two-column:hover > .childBox > .ddmenu {
  display: inline-block;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.two-column > .childBox {
  width: 600px;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox.two-column > .childBox > .ddmenu {
  width: 49%;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .menuLink {
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .menuLink {
  background-color: rgba(0, 0, 0, 0.025);
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .activeMenu,
.sidebarNav-horizontal .horizontal-navbar .parentBox .activeMenu {
  color: #ffffff;
  background-color: var(--accent);
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .down-icon .mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .childBox {
  border-radius: 7px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  position: absolute;
  width: 250px;
  padding: 15px;
  display: none;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox .childBox .ddmenu {
  display: block;
  border-radius: 7px;
  margin: 0px 0 2px 0;
  position: relative;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover:hover > .childBox > .ddmenu:hover {
  background-color: rgba(0, 0, 0, 0.025);
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox {
  left: 218px;
  top: 0px;
  z-index: 9;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  left: 222px;
  top: 0;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox {
  display: block;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox {
  display: block;
}
.sidebarNav-horizontal .horizontal-navbar .parentBox:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  display: block;
}

.sidebarNav-horizontal .horizontal-navbar .parentBox.pactive > a, .sidebarNav-horizontal .horizontal-navbar .parentBox.pactive > a:hover, .sidebarNav-horizontal .horizontal-navbar .parentBox.pactive:hover > a {
  color: white !important;
  border-radius: 7px;
}

.ltr .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox {
  right: 250px;
  left: unset;
}
.ltr .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  right: 250px;
  left: unset;
}

.rtl .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox {
  left: 250px;
  right: unset;
}
.rtl .sidebarNav-horizontal .horizontal-navbar .parentBox:last-child:hover > .childBox > .ddmenu:hover > .childBox > .ddmenu:hover > .childBox {
  left: 250px;
  right: unset;
}

.cal-event-action {
  text-decoration: none;
  margin-left: 5px;
  color: #ffffff;
}

.cal-month-view {
  background-color: transparent !important;
}

.cal-month-view .cal-open-day-events {
  background-color: #333f55 !important;
}

.cal-month-view .cal-day-badge {
  background-color: var(--primary) !important;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: var(--primary) !important;
}

.max-text {
  max-width: 250px;
  line-height: 1.57;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ngx-pagination {
  margin-bottom: 15px !important;
}
.ngx-pagination .current {
  background: var(--primary) !important;
  border-radius: 7px;
}
.ngx-pagination a {
  border-radius: 7px !important;
}
.ngx-pagination button {
  border-radius: 7px !important;
}

.social-chips img {
  margin-left: -9px;
  border: 2px solid #ffffff;
}
.social-chips img:first-child {
  margin-left: 0;
}

.profiletimeline {
  position: relative;
  padding-left: 40px;
  margin: 10px 10px 0 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.profiletimeline .sl-item {
  margin-top: 8px;
  margin-bottom: 30px;
  display: flex;
}
.profiletimeline .sl-left {
  margin-left: -60px;
  z-index: 1;
  margin-right: 15px;
}

.minus-img {
  margin-bottom: -65px !important;
}

.total-sales-icon {
  position: absolute;
  left: 46%;
  top: 45%;
  opacity: 0.6;
}

.welcome-img {
  position: absolute;
  right: -18px;
  top: -29px;
  width: 225px;
}

.welcome-img {
  margin-bottom: -65px;
  margin-top: -15px;
}

.timeline {
  position: relative;
}
.timeline .timeline-item {
  position: relative;
  height: 70px;
}
.timeline .timeline-item .time {
  padding: 6px 16px 6px 0;
  min-width: 90px;
  flex-shrink: 0;
}
.timeline .timeline-item .desc {
  padding: 6px 16px;
}
.timeline .timeline-item .timline-border {
  width: 1px;
  height: 100%;
  background-color: #e5eaef;
  flex-shrink: 0;
}
.timeline .timeline-item .point {
  flex-direction: column;
}
.timeline .timeline-item .point .timeline-badge {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: transparent;
  flex-shrink: 0;
}
.timeline .timeline-item .point .timeline-badge.border-primary {
  border: 2px solid var(--primary);
}
.timeline .timeline-item .point .timeline-badge.border-accent {
  border: 2px solid var(--accent);
}
.timeline .timeline-item .point .timeline-badge.border-success {
  border: 2px solid #2AD0B6;
}
.timeline .timeline-item .point .timeline-badge.border-warning {
  border: 2px solid #ffb22b;
}
.timeline .timeline-item .point .timeline-badge.border-error {
  border: 2px solid #fc4b6c;
}
.timeline .timeline-item .point .timeline-border {
  width: 1px;
  height: 100%;
  background-color: #e5eaef;
  flex-shrink: 0;
}
.timeline .timeline-item:last-child .timeline-border {
  display: none !important;
}

.little-profile .pro-img,
.little-profile .pro-img:first-child {
  margin-top: -80px;
  margin-bottom: 20px;
  z-index: 1;
  position: relative;
}

@media (min-width: 768px) {
  .profile-bg-height {
    max-height: 187px;
  }
}
.profile-bg-height {
  min-height: 192px;
}

.comment-widgets {
  position: relative;
  margin-bottom: 10px;
}
.comment-widgets .comment-row {
  border-left: 3px solid transparent;
  padding: 15px;
}
.comment-widgets .comment-row:hover, .comment-widgets .comment-row.active {
  border-color: var(--primary);
}

.comment-text:hover .comment-footer .action-icons, .comment-text.active .comment-footer .action-icons {
  visibility: visible;
}
.comment-text p {
  max-height: 50px;
  overflow: hidden;
}

.comment-footer .action-icons {
  visibility: hidden;
}
.comment-footer .action-icons a {
  padding-left: 15px;
  vertical-align: middle;
  color: #7c8fac;
}
.comment-footer .action-icons a:hover, .comment-footer .action-icons a.active {
  color: var(--primary);
}

.msg-box .mat-mdc-menu-item .mdc-list-item__primary-text {
  width: 100%;
}

.minus-margin {
  margin-bottom: -60px !important;
}

.remove-th-table {
  margin-top: -70px;
}
.remove-th-table.mat-mdc-table .mat-mdc-row:hover,
.remove-th-table .mat-mdc-table .mat-mdc-footer-row:hover {
  background-color: transparent !important;
}

#vector-map.sm-map {
  margin-top: -60px;
}
#vector-map .dx-export-menu,
#vector-map .dxm-title,
#vector-map .dxm-legend,
#vector-map .dxm-control-bar,
#vector-map tspan {
  display: none !important;
}
#vector-map .dxm-background {
  stroke-width: 0;
  fill: transparent;
}
#vector-map .dxm-layer path {
  stroke-width: 0;
  fill: #e2e2e2;
}

.blank-layout-container {
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
}

.auth-title {
  font-size: 36px;
  line-height: 44px;
}

.bg-gredient::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../../../assets/images/backgrounds/login-bg.jpg);
  background-size: cover;
  z-index: -1;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 50% 0%;
  }
}
.img-height {
  height: calc(100vh - 83px);
}

.custom-row.row {
  margin-right: -8px;
  margin-left: -8px;
}
.custom-row.row .col-2,
.custom-row.row .col-12 {
  padding-right: 8px;
  padding-left: 8px;
}

.or-border {
  position: relative;
  text-align: center;
}
.or-border:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100px;
  top: 50%;
  height: 1px;
  background: #e5eaef;
}
.or-border:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100px;
  top: 50%;
  height: 1px;
  background: #e5eaef;
}

.boxed-auth {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.max-width-form {
  max-width: 450px;
}

.mat-mdc-form-field.mat-form-field-invalid .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-form-field-invalid .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-form-field-invalid .mdc-notched-outline__trailing {
  border-color: #fc4b6c !important;
}

.error-msg {
  position: absolute;
  top: -19px;
  right: 3px;
}

.signin-button:not(:disabled) {
  background-color: var(--primary) !important;
}

.login-alert {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.container {
  max-width: 1200px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}
.container.full-width {
  display: flex;
  align-items: center;
  width: 100%;
}

.demos-dd {
  max-width: 1200px !important;
}

.img-overlay {
  overflow: auto;
  position: relative;
}
.img-overlay .overlay-content {
  display: none;
}
.img-overlay:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background-color: rgba(55, 114, 255, 0.2);
}
.img-overlay:hover .overlay-content {
  display: block;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  min-width: 120px;
  z-index: 9;
}

.topbar-xl {
  height: 80px;
  z-index: 1;
}

@media (min-width: 900px) {
  .banner-title {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}
@media (max-width: 1200px) {
  .banner-section {
    padding-top: 60px;
  }
}
.banner-title {
  font-size: 36px;
  line-height: 44px;
}

.banner-subtitle {
  line-height: 25px;
}

.btn-custom {
  height: 56px !important;
  padding: 0 52px !important;
  font-size: 16px;
}
.btn-custom.sm {
  padding: 0 34px !important;
}

@media (max-width: 767px) {
  .btn-custom {
    padding: 0 36px !important;
  }
}
.banner-slider {
  background-color: #ecf6ff;
  min-width: 2000px;
  height: calc(100vh - 100px);
  max-height: 790px;
  border-radius: 7px;
  overflow: hidden;
}

.slider-img {
  animation: slideup 35s linear infinite;
  margin: 0 30px;
}

.slider-img2 {
  animation: slidedown 35s linear infinite;
}

@keyframes slideup {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0px, -100%, 0px);
  }
}
@keyframes slidedown {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0px, 0, 0px);
  }
}
.spacer {
  padding: 80px 0 100px;
}

.section-title {
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
}

@media (min-width: 991px) {
  .section-title {
    font-size: 36px;
    line-height: 43px;
  }
}
.bg-extra-light {
  background-color: #f6f9fc;
}

.demo-slider {
  margin-top: 40px;
}
.demo-slider .demo-slide {
  animation: slide 45s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
.lh-base {
  line-height: 1.75;
}

.shape-card {
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

.spacer-sm {
  padding-top: 60px;
  padding-bottom: 30px;
}

.spacer-bottom {
  padding-bottom: 60px;
}

.section-title2 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
}

.c2a-card {
  margin-top: -70px;
  background-repeat: no-repeat;
  background-position: center;
}

.border-white {
  border: 1px solid #ffffff !important;
}

.border-accent {
  border: 1px solid var(--accent) !important;
}

.border-primary {
  border: 1px solid var(--primary) !important;
}

.hstack {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 767px) {
  .hstack {
    flex-direction: column;
  }
  .bg-size-cover {
    background-size: cover;
  }
}
.truncate-3-line {
  max-height: 4.4rem;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

[dir=rtl] .upgrade-bg {
  left: 0;
  right: unset;
  transform: scaleX(-1);
}
[dir=rtl] .customizerBtn {
  left: 30px;
  right: unset;
}
[dir=rtl] .branding img {
  transform: scaleX(-1);
}
[dir=rtl] .breadcrumb-item:after {
  margin-right: 15px;
  margin-left: 35px;
}
[dir=rtl] .b-r-1 {
  border-right: 0 !important;
  border-left: 1px solid #e5eaef;
}
[dir=rtl] .b-l-1 {
  border-left: 0 !important;
  border-right: 1px solid #e5eaef;
}
[dir=rtl] .profile-dd {
  margin-right: 26px !important;
  margin-left: 0 !important;
}
[dir=rtl] .user-profile .profile-img {
  margin-left: 0;
  margin-right: 30px;
}
[dir=rtl] .sidebar-list.mdc-list .menu-list-item .mdc-list-item__start {
  margin-right: 0 !important;
  margin-left: 14px !important;
}
[dir=rtl].sidebarNav-mini .contentWrapper {
  margin-right: 80px !important;
  margin-left: 0 !important;
  transition: width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
[dir=rtl].sidebarNav-mini .sidebarNav .sidebar-list .menu-list-item .mdc-list-item__start {
  margin-right: 8px !important;
  margin-left: 7px !important;
}
[dir=rtl].sidebarNav-mini .sidebarNav:hover .sidebar-list .menu-list-item .mdc-list-item__start {
  margin-right: 0 !important;
  margin-left: 16px !important;
}
[dir=rtl] .profiletimeline {
  padding-left: 0;
  padding-right: 40px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 0;
}
[dir=rtl] .profiletimeline .sl-left {
  margin-left: 15px;
  margin-right: -60px;
}